import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container } from "@material-ui/core";
import Link from "next/link";
import CollapseMenu from "./CollapseMenu";
import UserMenu from "./User/UserMenu";
import { useAuth } from "hooks/useAuth";

const useStyles = makeStyles(theme => ({
  header: {
    fontFamily: theme.typography.fontFamily,
    height: "100px",
    display: "flex",
    alignItems: "center",
    background: "#ffffff 0% 0% no-repeat padding-box",
    padding: "26px 0px",
    borderBottom: "1px solid #E9ECEE",
    position: "relative",
    width: "100%",
    "& a": {
      textDecoration: "none",
      color: "#27272D",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: "16px",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  regularLink: {
    cursor: "pointer",
    padding: "0 32px"
  },
  offerButton: {
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #27272D",
    padding: "12px 24px",
    marginRight: "16px"
  },
  logo: {
    height: "45px"
  },
  logo2: {
    height: "35px",
    padding: "0"
  },
  collapseMenu: {
    display: "none"
  },
  svg: {
    float: "right",
    height: "19px",
    paddingTop: "2px"
  },
  "@media (max-width: 1024px)": {
    header: {
      height: "64px",
      padding: "14px 14px 0 0",
      borderBottom: "1px solid #DFDFDF"
    },
    hidingMenu: {
      display: "none"
    },
    collapseMenu: {
      display: "block"
    },
    logo2: {
      height: "30px"
    }
  },
  "@media (max-width: 450px)": {
    logo2: {
      height: "20px"
    }
  }
}));

const menu = [
  {
    label: "Login",
    href: "/login",
    target: "_self"
  }
];

const Logo = () => {
  const classes = useStyles();

  return (
    <img
      src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/loopbrasil/b/assets/o/loop-carros/logo.svg"
      alt="Loop Veículos"
      className={classes.logo}
    />
  );
};

export const Header = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();

  function validatedMenu() {
    return menu
      .map(item => {
        if (item.label === "Login" && isAuthenticated) return null;

        return item;
      })
      .filter(item => item !== null);
  }

  return (
    <header className={classes.header}>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Link href="/">
              <a className={classes.logo}>
                <Logo />
              </a>
            </Link>
          </Grid>
          <Grid item>
            <Grid
              className={classes.hidingMenu}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {validatedMenu().map(({ label, href, target }) => {
                return (
                  <Grid key={label} item>
                    <Link href={href}>
                      <a
                        href={href}
                        target={target}
                        className={classes.regularLink}
                      >
                        {label}
                      </a>
                    </Link>
                  </Grid>
                );
              })}
              {isAuthenticated ? <UserMenu /> : null}
            </Grid>
            <CollapseMenu
              items={validatedMenu()}
              className={classes.collapseMenu}
            />
          </Grid>
        </Grid>
      </Container>
    </header>
  );
};

export default Header;
