import React, { useState } from "react";
import Link from "next/link";
import { Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { signOut } from "next-auth/react";

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-search-account-menu";

  const userMenu = [
    { label: "Editar meus dados", href: "/cadastro/alterar" },
    { label: "Minhas ofertas", href: "/minhas-ofertas" },
    { label: "Sair", onClick: () => signOut({ redirect: "/" }) }
  ];

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid key="profile" item>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        style={{ margin: "0 16px" }}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        variant="menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleProfileMenuClose}
      >
        <div>
          {userMenu.map(item => {
            if (item.onClick) {
              return (
                <MenuItem
                  onClick={() => {
                    item.onClick();
                    handleProfileMenuClose();
                  }}
                  key={item.label}
                >
                  {item.label}
                </MenuItem>
              );
            }

            return (
              <Link href={item.href} passHref key={item.href}>
                <MenuItem component="a">{item.label}</MenuItem>
              </Link>
            );
          })}
        </div>
      </Menu>
    </Grid>
  );
};

export default UserMenu;
