import React from "react";
import { Footer } from "@loop-brasil/material";
import Link from "next/link";

const LinkAdapter = ({ href, target, children }) => {
  if (href && !target) {
    return <Link href={href}>{children}</Link>;
  }

  return <>{children}</>;
};
const FooterLoop = () => {
  return (
    <Footer
      linkWrapper={LinkAdapter}
      informations={[
        {
          title: "Contato",
          item: [
            { label: "E-mail", description: "E-mail: sac@looprevenda.com.br" }
          ]
        }
      ]}
      menu={[
        {
          name: "Loop",
          links: [
            {
              label: "Política de privacidade",
              href:
                "https://loop.octadesk.com/kb/article/politica-de-privacidade",
              target: "_blank"
            },
            {
              label: "LGPD",
              href: "https://loop.octadesk.com/kb/article/lgpd",
              target: "_blank"
            },
            {
              href:
                "https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/loopbrasil/b/assets/o/loop%2FLoop_C%C3%B3digo%20de%20Conduta.pdf",
              target: "_blank",
              label: "Código de Conduta"
            }
          ]
        },
        {
          name: "Dúvidas",
          links: [
            {
              label: "Canal Aberto Webmotors",
              href: "https://aloetica.com.br/webmotors",
              target: "_blank"
            }
          ]
        }
      ]}
    />
  );
};

export default FooterLoop;
