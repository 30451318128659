import axios from "axios";

export const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Authorization: "Bearer " + process.env.NEXT_PUBLIC_API_KEY
  }
});

export const sigInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SIG_WEB
});

export const nextApiInstance = axios.create({
  baseURL: "/api"
});

export const postAuth = (username, password) =>
  instance.post("/account/auth", { username, password, store: "loopveiculos" });

export const getAuth = (username, password) =>
  nextApiInstance.post("/auth", { username, password });

export const postUpdateReferralCookie = referral =>
  nextApiInstance.post("/auth/referral/update", { referral });

export const postResendConfirmation = email =>
  instance.post(`/auth/confirmation/resend`, { email });

export const postConsent = origin =>
  instance.post(`/auth/policy-consent`, origin);

export const getUserProfile = userId => {
  return instance.get(`/user/${userId}/profile`);
};

export const postLead = data => instance.post(`/lead`, data);

export const getPrice = (fipeValue, mileage, rating) =>
  instance.get(`/pricing/${fipeValue}/${mileage}/${rating}`);

export const getStoreById = id =>
  instance.get(`/scheduling/store/${id}`).then(res => res.data);

export const getStore = () => instance.get(`/stores/accept-schedule`);

export const getOrders = partner => instance.get(`order/seller/${partner}`);

export const postDocumentOrder = (orderId, formData) =>
  instance.post(`/order/${orderId}/payment/receipt`, formData);

export const deleteDocumentOrder = orderId =>
  instance.delete(`/order/${orderId}/payment/receipt`);

export const getStateList = () => {
  return new Promise((resolve, reject) => {
    resolve({
      data: [
        { label: "Acre", id: "AC" },
        { label: "Alagoas", id: "AL" },
        { label: "Amapá", id: "AP" },
        { label: "Amazonas", id: "AM" },
        { label: "Bahia", id: "BA" },
        { label: "Ceará", id: "CE" },
        { label: "Distrito Federal", id: "DF" },
        { label: "Espírito Santo", id: "ES" },
        { label: "Goiás", id: "GO" },
        { label: "Maranhão", id: "MA" },
        { label: "Mato Grosso", id: "MT" },
        { label: "Mato Grosso do Sul", id: "MS" },
        { label: "Minas Gerais", id: "MG" },
        { label: "Pará", id: "PA" },
        { label: "Paraíba", id: "PB" },
        { label: "Paraná", id: "PR" },
        { label: "Pernambuco", id: "PE" },
        { label: "Piauí", id: "PI" },
        { label: "Rio de Janeiro", id: "RJ" },
        { label: "Rio Grande do Norte", id: "RN" },
        { label: "Rio Grande do Sul", id: "RS" },
        { label: "Rondônia", id: "RO" },
        { label: "Roraima", id: "RR" },
        { label: "Santa Catarina", id: "SC" },
        { label: "São Paulo", id: "SP" },
        { label: "Sergipe", id: "SE" },
        { label: "Tocantins", id: "TO" }
      ]
    });
  });
};

export const postNewsletterSubscribe = email =>
  instance.post("/post-officer/newsletter/subscribe", { email: email });

export const postSearchVehicle = (index, params) =>
  instance.post(`/search/${index}`, params);

export const getFilters = index => instance.get(`/vehicle/filters/${index}`);

export const getTermsStatus = (storeSlug, username) =>
  instance.get(`/auth/store/${storeSlug}/${username}`);

export const putTermsStatus = (storeSlug, username, status) =>
  instance.put(`/auth/store/${storeSlug}/${username}`, { status });

export const getOffers = (partner, vehicleId) =>
  instance.get(`/order/store/${partner}/product/${vehicleId}/best-offer`);

export const getAutomaticOffer = vehicleId =>
  instance.get(`/order/automatic-order/${vehicleId}`);

export const postAutomaticOffer = data =>
  instance.post(`/order/automatic-order`, data);

export const getCustomers = partner =>
  instance.get(`/seller/${partner}/customers`);

export const putOrderCustomer = (orderId, customerId) =>
  instance.put(`/order/${orderId}/customer/${customerId}`);

export const getOrder = orderId =>
  instance.get(`/order/${orderId}`).then(res => res.data);

export const verifyPasswordRecoveryToken = token =>
  instance.get(`/registration/password-recovery/${token}`);

export const putPasswordChange = (token, password) =>
  instance.put(`/registration/password-recovery/${token}/change-password`, {
    password
  });

export const postPasswordRecovery = email =>
  instance.post(`/registration/password-recovery`, { email });

export const getAddress = zipcode =>
  axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

export const getUserReferral = () =>
  instance.get("/registration/referral-contact").then(res => res.data);

export const getAvailableDates = storeId =>
  instance.get(`/scheduling/${storeId}/dates`).then(res => res.data);

export const getAvailableTimes = (storeId, chosenDate) =>
  instance
    .get(`/scheduling/${storeId}/times/${chosenDate}`)
    .then(res => res.data);

export const postScheduleVehiclePickup = data =>
  instance.post(`/scheduling/withdrawal`, data);

export const putScheduleVehiclePickup = data =>
  instance
    .put(`/scheduling/withdrawal/${data.sku}`, data)
    .then(res => res.data);
