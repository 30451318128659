import { instance } from "api";
import { useSession as useNextAuthSession } from "next-auth/react";

export const useAuth = () => {
  const session = useNextAuthSession();

  const token = session?.data?.token;
  const isAuthenticated = session?.status === "authenticated";
  const isLoading = session?.status === "loading";

  if (isAuthenticated && token) {
    instance.defaults.headers["Authorization"] = `Bearer ${token}`;
  }

  const getUserGroup = target => {
    if (!session || !session.data || !session.data.groups.length || !target)
      return null;

    return session.data.groups.find(
      ({ label }) => label.toLowerCase() === target.toLowerCase()
    );
  };

  return {
    session: session?.data,
    getUserGroup,
    isAuthenticated,
    isLoading
  };
};
