import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "next/link";
import { useAuth } from "hooks/useAuth";

const useStyles = makeStyles(theme => ({
  icon: {
    position: "absolute",
    top: "63px",
    right: "128px",
    width: "24px",
    height: "auto",
    padding: "2px 0",
    cursor: "pointer",
    zIndex: "1"
  },
  bar: {
    width: "100%",
    height: "2px",
    background: "#0C0C0C",
    marginBottom: "5px",
    transition: "0.3s"
  },
  bar1: {
    transform: "rotate(-45deg) translate(-9px, 6px)",
    transition: "0.3s"
  },
  bar2: {
    opacity: "0",
    transition: "0.3s"
  },
  bar3: {
    transform: "rotate(45deg) translate(-4px, -2px)",
    transition: "0.3s"
  },
  menu: {
    float: "right",
    padding: "5px 0",
    color: theme.palette.text.primary,
    fontSize: "16px",
    transition: "0.3s",
    right: "236px",
    top: "61px",
    position: "absolute",
    zIndex: "1",
    "& a": {
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  menuList: {
    padding: "0 30px",
    cursor: "pointer",
    "& a": {
      textDecoration: "none",
      color: theme.palette.text.primary
    }
  },
  subMenu: {
    listStyle: "none",
    padding: "10px 0 0 0"
  },
  svg: {
    float: "right",
    height: "19px",
    paddingTop: "2px"
  },
  menuActive: {
    opacity: "1",
    display: "flex",
    listStyle: "none",
    margin: "0"
  },
  menuInactive: {
    opacity: "0",
    display: "none"
  },
  "@media (max-width: 1024px)": {
    root: {
      display: "block"
    },
    menu: {
      right: "0",
      color: "#2E2D37",
      fontWeight: "500"
    },
    icon: {
      top: "24px",
      right: "10%"
    },
    menuActive: {
      display: "block",
      width: "100%",
      height: "100vh",
      background: "#fff",
      zIndex: "10"
    },
    subMenu: {
      padding: "0"
    },
    menuList: {
      padding: "0",
      "& a": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 20px",
        background: "#F7F7F8",
        height: "80px",
        width: "100%",
        borderBottom: "0.5px solid #DFDFDF",
        textDecoration: "none",
        "&:hover": {
          subMenu: {
            display: "block"
          }
        }
      }
    },
    subMenuList: {
      background: "#FFF",
      "& a": {
        textDecoration: "none",
        color: theme.palette.text.primary,
        display: "flex",
        alignItems: "center",
        padding: "0 20px",
        background: "#FFF",
        height: "80px",
        width: "100%",
        borderBottom: "0.5px solid #DFDFDF"
      }
    }
  }
}));

const userMenu = [
  { label: "Editar meus dados", href: "/cadastro/alterar" },
  { label: "Minhas ofertas", href: "/minhas-ofertas" },
  { label: "Sair", onClick: async () => signOut({ redirect: false }) }
];

const CollapseMenu = ({ items, className }) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const [collapse, setCollapse] = useState(false);

  return (
    <div className={`${classes.root} ${className}`}>
      <ul
        className={
          collapse
            ? `${classes.menu} ${classes.menuActive}`
            : `${classes.menu} ${classes.menuInactive}`
        }
      >
        {items.map(({ label, href }) => {
          return label === "Login" && isAuthenticated ? null : (
            <li key={label} className={classes.menuList}>
              <Link href={href}>
                <a href={href}>{label}</a>
              </Link>
            </li>
          );
        })}
        {isAuthenticated
          ? userMenu.map(item => {
              if (item.onClick) {
                return (
                  <li key={item.label} className={classes.menuList}>
                    <a onClick={item.onClick}>{item.label}</a>
                  </li>
                );
              }

              return (
                <li key={item.label} className={classes.menuList}>
                  <Link href={item.href}>
                    <a>{item.label}</a>
                  </Link>
                </li>
              );
            })
          : null}
      </ul>
      <div className={classes.icon} onClick={() => setCollapse(!collapse)}>
        <div
          className={collapse ? `${classes.bar} ${classes.bar1}` : classes.bar}
        ></div>
        <div
          className={collapse ? `${classes.bar} ${classes.bar2}` : classes.bar}
        ></div>
        <div
          className={collapse ? `${classes.bar} ${classes.bar3}` : classes.bar}
        ></div>
      </div>
    </div>
  );
};

export default CollapseMenu;
